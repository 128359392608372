
import FirstPage from './components/FirstPage';

function App() {
  return (
    <>
    <FirstPage/>
    </>
  );
}

export default App;
