import React from "react";
import { useState} from "react";
import "../components/styleFirstPage.css";
import watchImage from "../assets/modernist.png";
import watchPoints from "../assets/watchPoints.png";
import otpScreen from "../assets/otpScreen.png";
import museId from "../assets/MUSEID.png";
import createMuseId from "../assets/createMuseId.png";
import watchAndPhn from "../assets/watchAndPhn.png";
import rectangle from "../assets/rectangle.png";
import InternetBLE from "../assets/InternetBLE.png";
import watchPairing from "../assets/watchPairing.png";
import pairManually from "../assets/pairManually.png";
import caliberate from "../assets/caliberate.png";
import dailyUsage from "../assets/dailyUsage.png";
import trobleShooting from "../assets/trobleShooting.svg";
import watchUserGuid from "../assets/watchUserGuid.svg";
import activityMyWatch from "../assets/activityMyWatch.svg";
import runningActivity from "../assets/runningActivity.svg";
import stepGoalMeter from "../assets/stepGoalMeter.svg";
import activityTab from "../assets/activityTab.svg";
import dayProgress from "../assets/dayProgress.png";
import acitvityGraph from "../assets/acitvityGraph.svg";
import averageStepCalc from "../assets/averageStepCalc.svg";
import activityScore from "../assets/activityScore.svg";
import yellowCircle from "../assets/yellowCircle.svg";
import scoreCard from "../assets/scoreCard.svg";
import activityGraphColored from "../assets/activityGraphColored.svg";
import goalStreakCard from "../assets/goalStreakCard.png";
import streakSection from "../assets/streakSection.svg";
import sleepOption from "../assets/sleepOption.svg";
import setAGoal from "../assets/setAGoal.svg";
import weekDays from "../assets/weekDays.svg";
import windDown from "../assets/windDown.svg";
import smartWakeRange from "../assets/smartWakeRange.svg";
import sleepScore from "../assets/sleepScore.svg";
import focusModeDefault from "../assets/focusModeDefault.svg";
import Schedule from "../assets/Schedule.svg";
import nameYourFocus from "../assets/nameYourFocus.svg";
import allowedForNotification from "../assets/allowedForNotification.svg";
import ScheduleAutomation from "../assets/ScheduleAutomation.svg";
import dialNotification from "../assets/dialNotification.svg";
import addRemender from "../assets/addRemender.svg";
import ScheduleRemenders from "../assets/ScheduleRemenders.svg";
import addRemenderButton from "../assets/addRemenderButton.svg";
import createTimeSlot from "../assets/createTimeSlot.svg";
import emergencyContact from "../assets/emergencyContact.svg";
import fallDetection from "../assets/fallDetection.svg";
import SOSEme from "../assets/SOSEme.svg";
import appCenterTab from "../assets/appCenterTab.svg";
import addMoreAppsCenter from "../assets/addMoreAppsCenter.png";
import CalMsgAlarm from "../assets/CalMsgAlarm.svg";
import callerIdInWatch from "../assets/callerIdInWatch.svg";
import groupNameCallerId from "../assets/groupNameCallerId.png";
import rotateToChangeTheNumber from "../assets/rotateToChangeTheNumber.svg";
import callerIdTab from "../assets/callerIdTab.svg";
import appCenterMainImage from "../assets/appCenterMainImage.png";
import changeSecondarytimeZone from "../assets/changeSecondarytimeZone.svg";
import addSecondaryTimer from "../assets/addSecondaryTimer.svg";
import secondaryTimer from "../assets/secondaryTimer.svg";
import halfDial from "../assets/halfDial.svg";
import buttonControls from "../assets/buttonControls.svg";
import buttonPresets from "../assets/buttonPresets.svg";
import createNewPreset from "../assets/createNewPreset.svg";
import findPhone from "../assets/findPhone.png";
import buttonToFindPhone from "../assets/buttonToFindPhone.svg";
import alarmDial from "../assets/alarmDial.svg";
import alarmTimes from "../assets/alarmTimes.svg";
import alarmTab from "../assets/alarmTab.svg";
import addAlarms from "../assets/addAlarms.svg";
import setAlarmTimming from "../assets/setAlarmTimming.svg";
import myWatchControlMultipleMuse from "../assets/myWatchControlMultipleMuse.png";
import addDeviceControlMultiMuse from "../assets/addDeviceControlMultiMuse.svg";
import selectDeviceToControl from "../assets/selectDeviceToControl.svg";
import myWatchReset from "../assets/myWatchReset.png";
import firmwareUpdates from "../assets/firmwareUpdates.svg";
import myProfile from "../assets/myProfile.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    MDBFooter,
  } from 'mdb-react-ui-kit';

const FirstPage = () => {
  const [showToc, setShowTOC] = useState(true);
  const [showFeatures, setShowFeatures] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleShowTOC = () => {
    setShowFeatures(false);
    setShowTOC(true);
  };

  const handleShowFeatures = () => {
    setShowTOC(false);
    setShowFeatures(!showFeatures);
  };

  return (
    <>
    {/* ---------------header------------------ */}
    <nav className="navbar bg-dark">
  <div className="headerMuse">
    <div className="headerText">Muse</div>
  </div>
</nav>
{/* ---------------header------------------ */}

      <div className="ParentContainerFirstPage">
        <div className="headerContainer">
          <div className="UserGuidHeading">
            <h1 style={{ color: "#fff", fontWeight: "bold" }}>
              Muse Watch User Guide
            </h1>
          </div>
          <div className="SeriesNameHeading">
            <h2
              style={{
                color: "#98989b",
                fontWeight: "600",
                fontStyle: "italic",
              }}
            >
              {"(Modernist Series)"}
            </h2>
          </div>
        </div>

        <div className="MuseWatchMainContainer">
          <img src={watchImage} alt="modernist" />
        </div>
      </div>

      <div className="getStartedContainer">
        <div>
          <h2
            style={{ color: "#fff", fontWeight: "bold", fontSize: "xx-large", margin:"30px 0px" }}
          >
            Get started
          </h2>
        </div>
        <div className="getStartedDescription">
          <p style={{ fontSize: "x-large" }}>
            This guide helps you make the most of your Muse Watch. It contains
            everything you need to know about the Muse watch and the connected
            app.
          </p>
          <p style={{ fontSize: "x-large" }}>
            You can check this guide for any query you have regarding the watch.
          </p>
        </div>
      </div>
      {/* --------------------------End of Get started section----------------------------------- */}

      <div className="KnowYourWatchContainerParent">
        <div className="KnowYourWatchHeading">
          <h1 style={{ color: "#fff", fontWeight: "bold", marginTop:"20px"}}>
            Know your Muse watch
          </h1>
        </div>
        <div className="KnowYourWatchContainer">
          <div 
          style={{display:"flex",
                width:"100%"
        }}
          className="KnowYourWatchImage">
            <img
              style={{ objectFit: "contain", maxHeight: "300px",
                margin:"20px auto"
            }}
              src={watchPoints}
              alt="watchPoints"
            />
          </div>
          <div className="KnowYourWatchPoints">
            <div style={{ color: "#fff" }} className="KnowYourWatchPointsChild">
              <p>1. An hour and minute hand</p>
              <p>2. Buttons</p>
              <p>3. Chrono dial and hand</p>
              <p>4. Watch strap</p>
            </div>
          </div>
        </div>
      </div>

      <div className="parentAccordionContainer">
        <div id="accordion" className="accordion-container">
          <article
            style={{ marginBottom: "10px", marginRight: "5px" }}
            className="content-entry"
            onClick={handleShowTOC}
          >
            <h4
              style={{ color: "#ffa00b", fontSize: "x-large" }}
              className={`article-title ${showToc ? "keepBackground" : ""}`}
            >
              {/* <i style={{ color: "#ffa00b", margin:"auto"}}></i> */}
              Contents
            </h4>
          </article>

          <article
            className="content-entry"
            style={{ marginBottom: "10px", marginRight: "5px" }}
            onClick={handleShowFeatures}
          >
            <h4
              style={{ color: "#ffa00b", fontSize: "x-large" }}
              className={`article-title ${
                showFeatures ? "keepBackground" : ""
              }`}
            >
              Features
            </h4>
          </article>
        </div>
        <div className="DisplayContentArea">
          {showToc ? (
            <>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Set up your muse watch
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="accordionDetailsContainer"
                  >
                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "0px 15px",
                      }}
                    >
                      1. App installation
                    </p>
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Use the QR code on the packaging or search for Muse in
                        the Google play store to find the compatible app.
                      </p>
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        You can also download the app using the following link:{" "}
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#ffa00b",
                            cursor: "pointer",
                          }}
                          href="https://play.google.com/store/apps/details?id=com.conzumex.musewatch"
                        >
                          Link here
                        </a>
                      </p>
                    </div>

                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "0px 15px",
                        marginTop:"30px"
                      }}
                    >
                      2. Account creation
                    </p>
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        You need to create a Muse ID to connect your device and
                        utilize all the services provided by Muse. Follow the
                        steps below after you install the app:
                      </p>
                      <p
                        style={{
                          margin: "10px 20px",
                        }}
                      >
                        1. Muse needs your phone number to register your device.
                        Verify your number with a One-TimePassword.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                          }}
                          src={otpScreen}
                          alt="otpscreen"
                        />
                      </div>
                      <p
                        style={{
                          margin: "10px 20px",
                        }}
                      >
                        2. Enter a username and set a password.
                      </p>

                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "250px",
                          }}
                          src={museId}
                          alt="museID"
                        />
                      </div>
                      <p
                        style={{
                          margin: "10px 20px",
                        }}
                      >
                        3. Muse needs your first name, second name, and birth
                        date details to verify your account in case of any
                        queries related to your Muse ID.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "250px",
                          }}
                          src={createMuseId}
                          alt="otpscreen"
                        />
                      </div>

                      <p
                        style={{
                          fontSize: "large",
                          color: "#fff",
                          margin: "0px 15px",
                          marginTop:"30px"
                        }}
                      >
                        3. Setting up your muse watch
                      </p>

                      <div
                        style={{
                          margin: "10px 0px",
                          color: "rgb(210 210 211)",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px 20px",
                          }}
                        >
                          Follow the onscreen instructions in the app to enjoy a
                          seamless pairing experience. Once your watch is set
                          up, you can enjoy the Muse experience.
                        </p>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          1. Bring the watch closer to the phone
                        </p>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <img
                            style={{
                              margin: "10px auto",
                              maxWidth: "250px",
                            }}
                            src={watchAndPhn}
                            alt="watchAndPhn"
                          />
                        </div>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          2. You can either scan the QR on the packaging or pair
                          your watch manually.
                        </p>
                      </div>

                      <p
                        style={{
                          fontSize: "large",
                          color: "#fff",
                          margin: "0px 15px",
                          marginTop:"30px"
                        }}
                      >
                        Scan your QR
                      </p>
                      <div
                        style={{
                          margin: "10px 0px",
                          color: "rgb(210 210 211)",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px 20px",
                          }}
                        >
                          1. Once you give access to your phone camera, point
                          the camera in such a way that the QR code is aligned
                          in the orange box in the middle of the app screen.
                        </p>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <img
                            style={{
                              margin: "10px auto",
                              maxWidth: "250px",
                            }}
                            src={rectangle}
                            alt="rectangle"
                          />
                        </div>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          2. Ensure that your phone’s Bluetooth and internet
                          connection are stable and turned on
                        </p>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <img
                            style={{
                              margin: "10px auto",
                              maxWidth: "250px",
                            }}
                            src={InternetBLE}
                            alt="InternetBLE"
                          />
                        </div>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          3. Once your Muse watch is detected, the app will
                          guide you through a series of steps to complete the
                          setup of your watch
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: "large",
                          color: "#fff",
                          margin: "0px 15px",
                          marginTop:"30px"
                        }}
                      >
                        Manual Pairing
                      </p>
                      <p
                        style={{
                          margin: "10px 20px",
                        }}
                      >
                        1. Ensure that your phone’s Bluetooth and internet
                        connection are stable and turned on
                      </p>
                      <p
                        style={{
                          margin: "10px 20px",
                        }}
                      >
                        2. Long press the top button for 3 seconds to put your
                        watch in pairing mode
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "250px",
                          }}
                          src={watchPairing}
                          alt="watchPairing"
                        />
                      </div>
                      <p
                        style={{
                          margin: "10px 20px",
                        }}
                      >
                        3. Select your device in the list of Available devices
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "250px",
                          }}
                          src={pairManually}
                          alt="pairManually"
                        />
                      </div>
                      <p
                        style={{
                          margin: "10px 20px",
                        }}
                      >
                        4. Once you select the device, follow the on-screen
                        instructions to complete the Setup
                      </p>

                      <p
                        style={{
                          fontSize: "large",
                          color: "#fff",
                          margin: "0px 15px",
                          marginTop:"30px"
                        }}
                      >
                        Trouble pairing your Muse watch
                      </p>
                      <div
                        style={{
                          margin: "10px 0px",
                          color: "rgb(210 210 211)",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "large",
                            margin: "0px 15px",
                          }}
                        >
                          Your Muse watch might already be connected to your
                          phone Bluetooth. . Follow the steps below and try to
                          pair the Muse watch again.
                        </p>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          1. Go to Bluetooth settings and find your Muse Watch
                          in the list of connected devices.
                        </p>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          2. Remove the device from connected devices by
                          clicking on “Forget device”.
                        </p>
                        <p
                          style={{
                            fontSize: "large",
                            margin: "0px 15px",
                          }}
                        >
                          Your Muse watch might be paired with another phone. In
                          order to pair your watch to a new phone, you have to
                          first disconnect the device from the already paired
                          phone by logging in to the Muse app. Once removed, you
                          can try the steps mentioned in the “Setting up your
                          watch” section to connect your Muse Watch
                        </p>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          If you still can’t pair your watch,{" "}
                          <a
                            style={{
                              textDecoration: "none",
                              color: "#ffa00b",
                              cursor: "pointer",
                            }}
                            href="https://in.musewearables.com/pages/contact-us"
                          >
                            reach out to our customer support
                          </a>
                          or follow the troubleshooting guide for the pairing
                          process.
                        </p>
                      </div>

                      <p
                        
                        style={{
                          fontSize: "large",
                          color: "#fff",
                          margin: "10px 15px",
                          marginTop:"30px"
                        }}
                      >
                        4. Calibration
                      </p>
                      <div
                        style={{
                          margin: "10px 0px",
                          color: "rgb(210 210 211)",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "large",
                            margin: "0px 15px",
                          }}
                        >
                        Once the initial pairing process is complete, you need
                        to calibrate your watch and set the time. Follow the
                        steps below in the app to proceed:
                        </p>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          1. Make sure the hour and minute hands of your
                            watch are pointed towards 12’ o clock. If not, use
                            the calibrate manually option to move the hour and
                            minute hands of the Watch
                        </p>
                        <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            border:"2px solid #ffa00b",
                            borderRadius:'4px',
                            padding:"5px",
                            margin: "10px auto",
                            maxWidth: "250px",
                          }}
                          src={caliberate}
                          alt="pairManually"
                        />
                      </div>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                          2. Rotate the dial on the screen to align the hour
                            and minute hands of the watch to 12’ o clock and
                            then use the dial in the next screen to align the
                            Chrono hand to the triangle in the Chrono dial.
                        </p>
                        <p
                          style={{
                            margin: "10px 20px",
                          }}
                        >
                        3. Once aligned, click next to go to the next step of
                        the setup process.
                        </p>

                        </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Daily usage
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "0px 15px",
                        
                      }}
                    >
                      Using the Muse app 
                    </p>
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        You can find all the settings for the Muse watch in
                        the app. Your watch will also sync all the activity and
                        sleep data collected to the app.
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                       There are 3 tabs:
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={dailyUsage}
                          alt="dailyUsage"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        1. My Watch: This contains all your settings related
                           to the watch. You can also find the “My Profile” icon
                           to edit or update your personal information.

                        </p>
                        <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        2. Activity: You can find your activity and sleep
                        scores in this section. You will also find all the
                        alerts and notifications to help you make important
                        changes to your lifestyle.

                        </p>
                        <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        3. Discover: This section can help you navigate the
                        app and discover all the features of your Muse
                        Watch. You can also find information regarding
                        troubleshooting, warranty, and customer support in
                        this section.
                        </p>

                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Toubleshooting
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "0px 15px",
                        marginBottom:"30px"
                        
                      }}
                    >
                     Trouble Shooting and device diagnostics
                    </p>
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        When facing a problem with your Muse Watch, you
                        can use the in-app features to diagnose and solve
                        the issue.
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                       1. Navigate to the “Discover” tab.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={trobleShooting}
                          alt="trobleShooting"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        2. Find the “Troubleshoot” option in the list of
                        available topics.
                        </p>
                        <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        If you don’t find the relevant topic, you can refer to
                        the following link to find more FAQ.
                        </p>
                        <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/support"
                            >Help Center</a>
                        </p>
                        <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            If you still don’t find a solution, please contact our
                            customer support for assistance.
                        </p>
                        <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/contact-us"
                            >Contact us</a>
                        </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>Support</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "0px 15px",
                        marginBottom:"30px"
                        
                      }}
                    >
                     Reaching customer support
                    </p>
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Please use the link below to contact our customer
                        service:
                      </p>
                        <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/contact-us"
                            >Contact us</a>
                        </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Warranty
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "0px 15px",
                        marginBottom:"30px"
                        
                      }}
                    >
                     Warranty information
                    </p>
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your device is valid for a 1 year warranty. You can
                        find the terms regarding the Warranty and service
                        below.
                      </p>
                        <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/warranty-and-service-policy"
                            >Warranty and Service Policy.</a>
                        </p>
                        <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your device will be eligible for a return and
                        exchange, provided there has been some
                        manufacturing defect within the warranty period.
                        Refer to the document below for detailed terms
                        regarding the returns and exchanges.
                      </p>
                      <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/return-and-exchange-policies"
                            >Return and Exchange Policy</a>
                        </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Safety & Handling
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "0px 15px",
                        marginBottom:"30px"
                        
                      }}
                    >
                     Ways to view the user guide
                    </p>
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        You can find the user guide at
                      </p>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                    1. Go to the “Discover” tab in the Muse app and
                    find the User guide in the list of available topics.
                        </p>
                        <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={trobleShooting}
                          alt="trobleShooting"
                        />
                      </div>
                      <div style={{
                        display:"flex",
                        justifyContent:"center"
                      }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent:"space-between",
                          maxWidth:"300px",
                          alignItems:"center",
                          backgroundColor:"black",
                          borderRadius:"5px",
                          padding:"0px 8px",
                          paddingRight:"20px"

                        }}
                      >
                        <div>
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "200px",
                          }}
                          src={watchUserGuid}
                          alt="trobleShooting"
                        />
                        </div>
                       <div>
                        <span>Muse Watch user guide</span>
                       </div>
                      </div>
                      </div>
                        {/* <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            2. You can also find the User guide on our
                            website with the following 
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/warranty-and-service-policy"
                            > link.</a>
                        </p> */}
                        
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Desposing & Privacy policy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "0px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                        <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/terms-of-services"
                            >Terms of Services</a>
                        </p>
                      <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/privacy-policy"
                            >Privacy policy</a>
                        </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Feedback
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "0px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Please use the link below to contact us and give us
                        direct feedback.
                      </p>
                        <p
                          style={{
                            margin: "30px 20px",
                          }}
                        >
                            <a
                             style={{
                                textDecoration: "none",
                                color: "#ffa00b",
                                cursor: "pointer",
                              }}
                              href="https://in.musewearables.com/pages/contact-us"
                            >Contact us</a>
                        </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>

            </>
          ) : (
            <>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Activity
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Reach your fitness goals with your watch. Muse
                        Watch can track your activity and motivate you to
                        lead a more healthy life. Muse calculates an Activity
                        score, that rewards you for good habits. You can find
                        the activity score in the “Activity” tab.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "30px 20px",
                        // marginTop:"30px"
                        
                      }}
                    >
                      Setting up your steps goal 
                    </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                       You can personalize your activity goal by specifying
                        the number of steps you want to take in a day. Wear
                        your Muse watch throughout the day to track your
                        movement and get valuable insights about your
                        activity.
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        Challenge yourself as this can be a good way to get
                        moving throughout the day. You can set your steps
                        goal by:
                      </p>
                      <p
                        style={{
                          margin: "30px 25px",
                          marginBottom:"10px",
                        }}
                      >
                        1. Go to the My Watch tab section
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={activityMyWatch}
                          alt="activityMyWatch"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        2. Find “Activity” in the My Watch
                        </p>

                        <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={runningActivity}
                          alt="runningActivity"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        3. Click on Edit step goal
                        </p>
                        <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={stepGoalMeter}
                          alt="stepGoalMeter"
                        />
                      </div>

                        <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        4. Enter a new number to modify your step goal
                        </p>
                        <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "30px 20px",
                        
                      }}
                    >
                      Check your step goal progress
                    </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        You can check your progress on the Chrono dial on
                        the watch. The Chrono hand starts from the left and
                        rotates to the right as you make progress in your
                        daily step goal. You can also check the progress for
                        your step goal in the app.
                      </p>
                        <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        1. Go to the “Activity” tab.
                        </p>

                        <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={activityTab}
                          alt="activityTab"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        2.The first card will show you the Day’s progress.
                        </p>

                        <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "250px",
                          }}
                          src={dayProgress}
                          alt="dayProgress"
                        />
                      </div>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "30px 20px",
                        // marginTop:"30px"
                        
                      }}
                    >
                      Step trends 
                    </p>
                    <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        Your day’s number of steps looks great, but how do
                        they compare to last week, last month, or the last 6
                        months? The Trends feature can tell you that.
                      </p>
                      <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        1. Click on the Activity card in the “Today” tab
                        </p>
                        <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        2. You can navigate between the Day, Week, and
                        Month view to compare your statistics or view data
                        from a specific day.
                        </p>
                        <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"10px",
                            borderRadius:"5px"
                          }}
                          src={acitvityGraph}
                          alt="acitvityGraph"
                        />
                      </div>
                        <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        3. You will find the information for your average
                        steps, average distance, and average calories
                        burned for all the different timelines.
                        </p>
                        <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"10px",
                            borderRadius:"5px"
                          }}
                          src={averageStepCalc}
                          alt="averageStepCalc"
                        />
                      </div>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "30px 20px",
                        // marginTop:"30px"
                        
                      }}
                    >
                     Activity score
                    </p>
                    <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"10px",
                            borderRadius:"5px"
                          }}
                          src={activityScore}
                          alt="activityScore"
                        />
                      </div>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        Your activity score is designed to help you make the
                        optimum activity habits for improving your health.
                        There are three parameters considered for activity
                        score.
                      </p>
                      <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        1. <strong style={{color:"#fff"}}>Consistency:</strong> Sitting throughout the day is bad
                        for your health. Even if you exercise for 30 minutes
                        at one point of the day and stay idle for the rest of
                        the time, you are not doing your best to improve
                        your health. A consistency score rewards you for
                        balancing between rest and movement.
                        </p>
                        <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        To optimize your consistency score, move around
                        for at least 5 minutes every hour. This would come
                        down to taking around 100 steps every hour.
                        </p>
                        <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        But, make sure you don’t overdo it by moving
                        around continuously. This will bring down your
                        consistency scores. Plan your day so that you take
                        enough breaks between activities.
                        </p>
                        <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        2. <strong style={{color:"#fff"}}>Sufficiency:</strong>  This is to motivate you to complete
                        the steps goal that you have set for yourself.
                        Based on research, we suggest you aim for 10,000
                        steps a day.
                        </p>
                        <p
                          style={{
                            margin: "20px 30px",
                          }}
                        >
                        3. <strong style={{color:"#fff"}}>Efficiency:</strong>  Your body needs a balance of lowintensity, medium-intensity, and high-intensity
                        activities in a day. Include a run, swim, cycling
                        working or playing any sport for at least 30 mins a
                        day to optimize your efficiency score.
                        </p>
                        <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "30px 20px",
                        // marginTop:"30px"
                        
                      }}
                    >
                    How to calculate your activity score in a day?
                    </p>
                    <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        1. In the “Today” tab, find the floating “Score” button.
                        </p>
                        <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "0px auto",
                            maxWidth: "250px",
                            // backgroundColor:"black",
                            // padding:"10px",
                            // borderRadius:"5px"
                          }}
                          src={yellowCircle}
                          alt="yellowCircle"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                            2. Click on the button to open a pop-up that
                            processes the activity data collected.
                        </p>
                        <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            // backgroundColor:"black",
                            // padding:"10px",
                            // borderRadius:"5px"
                          }}
                          src={scoreCard}
                          alt="scoreCard"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                        3. Your score is calculated and updated in the
                        “Today” tab.
                        </p>
                        <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"10px",
                            borderRadius:"5px"
                          }}
                          src={activityScore}
                          alt="activityScore"
                        />
                      </div>
                      <p
                          style={{
                            margin: "20px 20px",
                          }}
                        >
                            4. Find out any improvements or falls in your activity
                            score compared to the previous day.
                        </p>
                        <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "30px 20px",
                        // marginTop:"30px"
                        
                      }}
                    >
                   Activity score trends
                    </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        Similar to your steps trends, you can view how your
                        activity score has been changing over the last week.
                        This can give you insights into how you have been
                        improving your activity habits for a longer period of
                        time.
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                      To view the activity score trends:
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                      1. In the “Activity” section, click on the “Activity score” card.
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                      2. You will the scores for the last week in graph.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"10px",
                            borderRadius:"5px"
                          }}
                          src={activityGraphColored}
                          alt="activityGraphColored"
                        />
                      </div>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                     3. You can change the dates for the week for viewing scores of different weeks.
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                    4. Click on any one day of the week in the graph to see the breakdown of your 3 parameters: activity.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "30px 20px",
                        // marginTop:"30px"
                        
                      }}
                    >
                   Using activity alerts
                    </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        Your watch gives you the right notifications at the
                        right time so that you can take action immediately.
                        Follow the alerts to improve your score and also
                        make meaningful changes towards a healthier
                        lifestyle.
                      </p>
                      <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center"
                      }}>
                        <div
                        style={{
                            margin:"30px auto",
                            backgroundColor:"#64646b7c",
                            borderRadius:"10px",
                            maxWidth:"280px",
                            padding:"10px"
                        }}
                        >
                    <p
                        style={{
                          margin: "5px 10px",
                          color:"red",
                          fontSize:"large"
                        //   marginBottom,
                        }}
                      >
                        <strong>You can do better!</strong>
                      </p>
                      <p
                        style={{
                          margin: "0px 10px",
                        //   marginBottom,
                        }}
                      >
                        You are below just above the average for this week, Push little hard to stand out.
                      </p>
                        </div>
                      </div>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "10px 20px",
                      }}
                    >
                    Weekly Streaks
                    </p>
                    <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        Streaks represent the number of consecutive days for
                        which you were able to complete your daily step
                        goals. Streaks motivate you to reach your steps goal
                        every day. Improvements to your health only happen
                        when you can sustain good habits over a longer time
                        period
                      </p>
                      <p
                        style={{
                          margin: "30px 20px",
                          marginBottom:"10px",
                        }}
                      >
                     You can find your streaks by following the steps below:
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        1. In the “Today” tab, you can find your current streak
                         at the bottom of the “Activity” card.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "250px",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={goalStreakCard}
                          alt="goalStreakCard"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                          marginBottom:"10px",
                        }}
                      >
                       2. Click on the “Activity” card and use the “Right
                       arrow” to navigate to the streaks section.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={streakSection}
                          alt="streakSection"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                          marginBottom:"10px",
                        }}
                      >
                        3. You can find information about your current streak,
                        longest streak, and percentage of days when you
                        completed your goal
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>Sleep</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Muse Watch can help you create a sleep schedule,
                        track your sleep, analyze your sleep quality, and then
                        report your sleep trends over time. To get started,
                        set a sleep goal, create a sleep schedule and wear
                        your Muse watch to sleep. Muse will do the rest to
                        help you improve your sleep habits.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                    Set a sleep goal
                    </p>
                    <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Based on your daily routine, set a sleep goal for the
                        hours of sleep you want to achieve. Based on
                        research, we suggest you aim for at least 7 hours of
                        sleep every day.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        You can set your sleep goal by:
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        1. In the “My Watch” tab, click on “sleep” in the list of settings.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={sleepOption}
                          alt="sleepOption"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        2. For the first time, you will be prompted to set
                        the sleep goal before going to the next screen.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={setAGoal}
                          alt="setAGoal"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        3. Once set, you can always come back to this
                        screen to edit your sleep goal.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        4. Enter a new number to modify your step goal.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                   Create a sleep schedule
                    </p>
                    <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                    Based on your sleep goal, Your Muse watch will
                    recommend an ideal bedtime and wake-up time. But
                    we know your routine might change on different
                    days of the week. For example, You might wake up
                    late on the weekends.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                    So you can create a personalized sleep schedule for
                    different days of the week using this setting.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={weekDays}
                          alt="weekDays"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        Set your bed time and wake time and Muse app
                        suggests you if you are settings timings to match
                        your sleep goal and alerts you if you are going less
                        than your goal.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={weekDays}
                          alt="weekDays"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        Your sleep schedule also has the following options
                        to help you sleep and wake up at the right time:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        <strong style={{color:"#fff"}}>1. Wind down: </strong>Wind down time can help you
                        prepare yourself for a good night’s sleep. During
                        the winddown, you will no longer receive any
                        notifications on your watch so you can rest and
                        slow down your mind and body. Your habits before
                        sleep have a drastic effect on the quality of your
                        sleep. So try to wind down at least 30 minutes
                        before bedtime.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={windDown}
                          alt="windDown"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        <strong style={{color:"#fff"}}>2. Smart wake: </strong> Your watch can analyze the quality
                        of your sleep and wake you up at the right time so
                        that you feel completely refreshed after you wake
                        up. Set a wake-up window so that your watch will
                        give a smart alarm on your wrist in that time
                        range.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={smartWakeRange}
                          alt="smartWakeRange"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        <strong style={{color:"#fff"}}>3. Phone alarm: </strong> Along with the smart alarm, you
                        can also create an alarm on your phone to assist
                        you in waking up. Select this option to get the
                        notification on your phone.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        Note: Your settings will only be applicable for a
                        specific sleep schedule. You can create different
                        settings for different sleep schedules.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        Once created, you can always edit or add a new
                        sleep schedule by navigating to the “Sleep” settings
                        anytime.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                        marginTop:"30px"
                      }}
                    >
                   Sleep score
                    </p>
                    <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            // padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={sleepScore}
                          alt="sleepScore"
                        />
                      </div>
                    <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        Your sleep score considers three important
                        parameters for optimizing your sleep habits.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        <strong style={{color:"#fff"}}>1. Consistency: </strong>Sleeping and waking up at the
                        same time every day will help drastically improve
                        your sleep quality for the best rest and recovery.
                        This will help you train your body to maintain a
                        rhythm. Try to sleep within a window of 20
                        minutes every day to get the best sleep
                        consistency score.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        <strong style={{color:"#fff"}}>2. Sufficiency: </strong>Sufficiency is based on whether you
                        meet your personal sleep goals every day. This
                        can be changed using the Sleep settings in the
                        watch section. Your body needs at least 7 hours of
                        sleep every night. Try to achieve that goal for a
                        great Sleep sufficiency score.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        <strong style={{color:"#fff"}}>3. Efficiency: </strong>We are usually awake for a little time
                        even when in bed. Our bodies take time to dive
                        into sleep. The awake time gives a good insight
                        into the quality of our sleep. Higher quality refers
                        to better recovery for our body. Taking time to
                        wind down before sleep and following Good sleep
                        habits will help you improve your sleep efficiency.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                        marginTop:"30px"
                      }}
                    >
                   How to calculate your sleep score ?
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. In the “Today” tab, find the floating “Score” button.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            // backgroundColor:"black",
                            // padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={yellowCircle}
                          alt="yellowCircle"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        2. Click on the button to open a pop-up that
                        processes the activity data collected.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            // backgroundColor:"black",
                            // padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={scoreCard}
                          alt="scoreCard"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        3. Your score is calculated and updated in the “Today” tab.

                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       4. Find out any improvements or falls in your activity score compared to the previous day.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                        marginTop:"30px"
                      }}
                    >
                  Sleep score trends
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        Similar to your steps trends, you can view how your
                        sleep score has been changing over the last week.
                        This can give you insights into how you have been
                        improving your sleep habits for a longer period of
                        time.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                        marginTop:"30px"
                      }}
                    >
                  To view the sleep score trends:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      1. In the “Today” section, click on the “sleep score” card.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            // padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={sleepScore}
                          alt="sleepScore"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      2. You will the scores for the last week in the graph.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          
                        }}
                      >
                        <img
                          style={{
                            margin: "20px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"5px",
                            borderRadius:"5px"
                          }}
                          src={activityGraphColored}
                          alt="activityGraphColored"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      3. You can change the dates for the week for
                        viewing scores of different weeks.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        4. Click on any one day of the week in the graph to
                        see the breakdown of your 3 parameters: sleep
                        sufficiency, efficiency, and consistency in the
                        section below.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                        marginTop:"30px"
                      }}
                    >
                  Sleep alerts
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                    Your watch gives you the right notifications at the
                    right time so that you can take action immediately.
                    Calculate your sleep score after you wake up and
                    Follow the alerts to improve your sleep the following
                    day to make meaningful changes towards a healthier
                    lifestyle.
                      </p>
                      <div style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center"
                      }}>
                        <div
                        style={{
                            margin:"30px auto",
                            backgroundColor:"#64646b7c",
                            borderRadius:"10px",
                            maxWidth:"280px",
                            padding:"10px"
                        }}
                        >
                    <p
                        style={{
                          margin: "5px 10px",
                          color:"red",
                          fontSize:"large"
                        //   marginBottom,
                        }}
                      >
                        <strong>You can do better!</strong>
                      </p>
                      <p
                        style={{
                          margin: "0px 10px",
                        //   marginBottom,
                        }}
                      >
                        You are below just above the average for this week, Push little hard to stand out.
                      </p>
                        </div>
                      </div>

    
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Focus modes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Focus can help you stay in the moment when you
                        want to concentrate on any specific activity. Focus
                        modes can help reduce distractions by filtering out
                        the notifications and sending you only those that you
                        want to receive. You can use the default focus modes
                        or create your own custom mode.
                      </p>
                      {/* <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                    For each mode, you can control
                    </p> */}
                    <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                    For each mode, you can control:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Which apps can send you notifications
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        2. Who can call you
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                    Your Muse watch will automatically switch between
                    different modes based on the Time of the day. This
                    can be controlled in the settings.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                  Muse watch creates two modes as default and sleep
                  based on your preferences during the setup process.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                    1. For “Default” mode, you will receive notifications
                    and calls based on the settings in the “My Watch”
                    section. Your watch stays in default mode if you
                    haven’t created any new modes.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                          }}
                          src={focusModeDefault}
                          alt="focusModeDefault"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        2. For “Sleep” mode, the automation times will be
                        updated according to your sleep schedule. You can
                        choose who can call you or what app notifications to
                        receive from the options on the screen.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={Schedule}
                          alt="Schedule"
                        />
                      </div>
                  <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                   Create a new mode
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Go to “Focus modes” in the list of settings in the
                        “My Watch” tab.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        2. Click on “Create a Custom Mode”.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        3. Select the name and icon for mode.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={nameYourFocus}
                          alt="nameYourFocus"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       4. Follow the onscreen instructions to add people and
                        apps that are allowed during this mode.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={allowedForNotification}
                          alt="allowedForNotification"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       5. You can either manually switch this mode on by
                        clicking on this Mode or let your watch automatically
                        switch to this mode at a specific time of the day.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={ScheduleAutomation}
                          alt="ScheduleAutomation"
                        />
                      </div>
    
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Reminders
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse watch can alert you regarding different
                        scheduled events in the day. You can add as many
                        reminders as you want to receive notifications on
                        your wrist.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                      The Chrono hand will point towards the “Calendar”
                      icon whenever you have a set reminder.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={dialNotification}
                          alt="dialNotification"
                        />
                      </div>
  
                      {/* <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                    For each mode, you can control
                    </p> */}
                    <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                    You can find the list of reminders by
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       1. Navigating to “Reminders” in the list of settings
                        in “My Watch” tab.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={addRemender}
                          alt="addRemender"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                         2. Click on all scheduled reminders
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={ScheduleRemenders}
                          alt="ScheduleRemenders"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                    To add a new reminder:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                         1. Navigating to “Reminders” in the list of settings
                        in “My Watch” tab.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        2. Click on “Add reminder”.
                      </p>

                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={addRemenderButton}
                          alt="addRemenderButton"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        3. Follow on screen instructions to enter the details
                        necessary for creating an event.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={createTimeSlot}
                          alt="createTimeSlot"
                        />
                      </div>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    SOS Emergency
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse watch will help you connect to
                        emergency services and your emergency contacts
                        whenever you trigger an SOS. You can find the
                        button controls for triggering SOS in the “button
                        control” section.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        When SOS is triggered, your Muse watch will alert
                        you on your wrist for 30 seconds. If you don’t
                        respond, Muse will contact the emergency services
                        and your emergency contacts and will share your
                        location.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={fallDetection}
                          alt="fallDetection"
                        />
                      </div>
  
                      {/* <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                    For each mode, you can control
                    </p> */}
                    <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                    To set up an SOS emergency:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Navigate to the “My Watch “ tab in the Muse app.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       2. Navigate to the “SOS Emergency” section in the list of settings.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={SOSEme}
                          alt="SOSEme"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                         3. Click on ‘Setup SOS’ tp add “Emergency
                            contact( Primary ) “ and follow the onscreen
                            instructions.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={emergencyContact}
                          alt="emergencyContact"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        4. Once verified, you need to add “Emergency
                        contact( secondary)” as well.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        5. Once both contacts are added, select whether
                        you want fall detection to be toggled on/off.
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    App center
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse Watch will send you notifications from
                        different apps to your wrist. You can add three
                        different types of app notifications to your Muse
                        watch:
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "250px",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={appCenterMainImage}
                          alt="appCenterMainImage"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        Each category is associated with an icon mentioned
                        above.
                      </p>
                      <p
                        style={{
                          margin: "20px 20px",
                        }}
                      >
                        You can add specific apps to each of the above
                        categories to get notified. When you receive an app
                        notification, your Chrono hand will point towards the
                        specific icon giving you information about the app.
                      </p>
                        <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                    To add app notifications:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       1. Navigate to the “My Watch “ tab in the Muse app.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      2. Navigate to the “App centre” section in the list of
                        settings.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={appCenterTab}
                          alt="appCenterTab"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      3. Click on “Add more apps” in the category of the
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "250px",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={addMoreAppsCenter}
                          alt="addMoreAppsCenter"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      4. Swipe right near the “Messages” to find the other
                        two categories of apps . when you swipe right, the
                        Chrono hand displayed on screen will direct you
                        towards the specific icon.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                            
                          }}
                          src={CalMsgAlarm}
                          alt="CalMsgAlarm"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                    5. Under each category add any number of apps using
                    the “Add more apps” button.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                    You can remove the apps or add new apps for each
                    category by navigating to the “App centre” in the list
                    of settings of the “My Watch” tab.
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Caller ID
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse Watch can tell you who is calling with the
                        help of caller alerts. You can set a total of 12 caller
                        IDs on your Muse watch. For every caller ID, The
                        minute and hour hand of your Muse watch will point
                        toward a specific number on your watch denoting
                        who is calling you.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={callerIdInWatch}
                          alt="callerIdInWatch"
                        />
                      </div>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                        You can edit existing Caller IDs or create
                        caller ID’s by:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       1. Navigate to the “My Watch “ tab in the Muse app.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                            2. Navigate to the “Caller ID” section in the list of
                            settings.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={callerIdTab}
                          alt="callerIdTab"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        3. Click on the existing “Caller ID” to modify the
                        settings.
                      </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       4. Click on “Create new Caller ID.”
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={rotateToChangeTheNumber}
                          alt="rotateToChangeTheNumber"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        5. Select a number for the caller ID by rotating the
                        dial on screen.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        6. Add a contact for this specific ID and number to
                        successfully create the “Caller ID.”
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                         7. You can add one contact or contact group for each
                        of the numbers from the “1 -12”.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "10px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px"
                          }}
                          src={groupNameCallerId}
                          alt="groupNameCallerId"
                        />
                      </div>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Secondary Timezone
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse Watch can show you the current time of
                        another country with a simple button press. You can
                        modify the button controls accordingly in the “Button
                        control” section.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                        To add a secondary timezone:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       1. Navigate to the “My Watch “ tab in the Muse app.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      2. Navigate to the “Secondary timezone” section in
                        the list of settings.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={secondaryTimer}
                          alt="secondaryTimer"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        3. Click on “Add Secondary Timezone” if you haven’t
                        added any.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={addSecondaryTimer}
                          alt="addSecondaryTimer"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        4. Click on “change timezone” to change the
                        timezone if you have already added.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={changeSecondarytimeZone}
                          alt="changeSecondarytimeZone"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        5. You can now check with either the secondary
                        timezone by clicking on the Watch button or the
                        onscreen button “Show secondary timezone”.
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Button controls
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse Watch buttons can be used to perform
                        different tasks. You can also create new button
                        controls to personalize the buttons to your
                        preferences.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={halfDial}
                          alt="halfDial"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       Your Muse Watch buttons can detect three types of
                        interactions:
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                        Single press, double press, and Long press
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       Here’s a list of tasks that can be performed using the buttons:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       1. Volume up
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       2. Volume down
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       3. Play/pause or Camera shutter
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       4. Next song
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      5. Previous song
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       6. Trigger SOS
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       7. View secondary timezone
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       8. Find my phone
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        By default, your Watch will have preset button
                        controls. You can view these controls by:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Navigate to the “My Watch “ tab in the Muse app.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       2. Navigate to the “Button Controls” section in the
                            list of settings.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={buttonControls}
                          alt="buttonControls"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={buttonPresets}
                          alt="buttonPresets"
                        />
                      </div>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                        You can also create a new preset by:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Navigate to the “My Watch “ tab in the Muse app
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       2. Navigate to the “Button Controls” section in the
                        list of settings.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                    3. Click on “Create a new preset” at the bottom of
                    the screen.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={createNewPreset}
                          alt="createNewPreset"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        Follow the on-screen instructions and select a task
                        from the above list for each button action.
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Find phone
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse Watch can help you locate your phone
                        when you misplace it. Using the button gesture, you
                        can trigger a sound and vibration on your phone with
                        your Muse Watch.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            // backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={findPhone}
                          alt="findPhone"
                        />
                      </div>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       You can find the appropriate button interaction or set
                        your own personal preference in the “button control
                        “ section in the “My watch” tab.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={buttonToFindPhone}
                          alt="buttonToFindPhone"
                        />
                      </div>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel11"}
                onChange={handleChange("panel11")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>Alarms</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                      <p
                        style={{
                          margin: "0px 20px",
                        }}
                      >
                        Your Muse watch can alert you regarding different
                        alarms in the day. You can add as many alarms as
                        you want to receive alerts on your wrist.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            // backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={alarmDial}
                          alt="alarmDial"
                        />
                      </div>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        The Chrono hand will point towards the “alarm” icon
                        whenever you have a set alarm.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        You can find the list of alarms by:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Navigate to “Alarms” in the list of settings in the “My Watch” tab.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={alarmTimes}
                          alt="alarmTimes"
                        />
                      </div>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                       To add a new alarm:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       1. Navigate to “Alarms” in the list of settings in the “My Watch” tab.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={alarmTab}
                          alt="alarmTab"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       2. Click on “Add alarm”.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={addAlarms}
                          alt="addAlarms"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       3. Follow on-screen instructions to enter the details necessary for creating an alarm.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={setAlarmTimming}
                          alt="setAlarmTimming"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        Note: You will receive notifications on your wrist for
                        all scheduled alarms irrespective of the focus mode.
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel12"}
                onChange={handleChange("panel12")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Control multiple muse devices
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                    <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                       Adding multiple muse devices:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        You can add multiple Muse devices to your app and
                        track data from all of them at one place.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        To add a new device:
                      </p>
                      <p
                        style={{
                          margin: "20px 40px",
                        }}
                      >
                       1. Click on “My Watch” in the top left corner of the
                            screen.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "250px",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={myWatchControlMultipleMuse}
                          alt="myWatchControlMultipleMuse"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 40px",
                        }}
                      >
                       2. Click on the “Add device” option.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            // backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={addDeviceControlMultiMuse}
                          alt="addDeviceControlMultiMuse"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 40px",
                        }}
                      >
                       3. Follow on-screen instructions to add another
                        device. Refer to the Set up your Muse Watch
                        section for the pairing process.
                      </p>
                      <p
                      style={{
                        fontSize: "large",
                        color: "#fff",
                        margin: "20px 20px",
                      }}
                    >
                       Switching between multiple devices:
                    </p>
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       You can check the data from multiple Muse Devices
                        using the same Muse app.
                      </p>
                      <p
                        style={{
                          margin: "20px 40px",
                        }}
                      >
                        1. Click on “My Watch” in the top left corner of the
                        screen.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "250px",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={myWatchControlMultipleMuse}
                          alt="myWatchControlMultipleMuse"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 40px",
                        }}
                      >
                     2. Select the other device from the list of linked
                        devices and click “open” to switch to the other
                        device.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            // backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={selectDeviceToControl}
                          alt="selectDeviceToControl"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                    Your My Watch, activity and discover sections will be
                    updated with information relevant to the other Muse
                    device.
                      </p>
                      
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel14"}
                onChange={handleChange("panel14")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Soft reset & Hard reset
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        In case your Muse Watch isn’t functioning, please
                        contact customer care. Based on directions from
                        Muse Team, you might have to perform a soft or
                        hard reset for your Watch. This will reset the watch
                        to its default factory settings.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        Note: A soft/hard reset will remove all the custom
                        settings and delete the previously loaded activity and
                        sleep data. Kindly perform this operation only after
                        referring to the Troubleshooting FAQs or customer
                        care.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        You can do a soft/hard reset by:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Navigate to the “Profile “ section in the “My
                        Watch” tab.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                        className="watchReset"
                          style={{
                            margin: "30px auto",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={myWatchReset}
                          alt="myWatchReset"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        2. Click on the current device in the “Linked devices”
                        section.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       3. In the Device screen, click on the Soft/hard reset
                        button available in the bottom of the screen.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                          textAlign:"center",
                          fontSize:"x-large",
                          fontWeight:"bolder",
                          color:"#0a85ff"
                        }}
                      >
                        Watch Soft reset
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                          textAlign:"center",
                          fontSize:"x-large",
                          fontWeight:"bolder",
                          color:"#0a85ff",
                          opacity:"0.4"
                        }}
                      >
                        Remove Device
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel15"}
                onChange={handleChange("panel15")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Remove device
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        In order to connect your Muse device to a different
                        phone/ muse ID, you have first to remove the device
                        from the list of connected devices.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                      To remove a device:
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        1. Navigate to the “Profile “ section in the “My
                            Watch” tab.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       2. Click on the current device in the “Linked devices”
                        section.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                       3. Within the Device screen, click on remove device
                            and follow onscreen instructions to complete the
                            task.
                      </p>

                      <p
                        style={{
                          margin: "20px 30px",
                          textAlign:"center",
                          fontSize:"x-large",
                          fontWeight:"bolder",
                          opacity:"0.4",
                          color:"#0a85ff"
                        }}
                      >
                        Watch Soft reset
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                          textAlign:"center",
                          fontSize:"x-large",
                          fontWeight:"bolder",
                          color:"#0a85ff",
                        
                        }}
                      >
                        Remove Device
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel16"}
                onChange={handleChange("panel16")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Firmware updates
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                            The team at Muse will keep updating the firmware
                            to solve any bugs or improve the functionality of
                            your Muse Watch.
                      </p>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        When you open the app, your Muse watch will look
                        for any updates and send you a pop-up to update if
                        necessary.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={firmwareUpdates}
                          alt="firmwareUpdates"
                        />
                      </div>
                      <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                        Click on update and follow the onscreen instructions
                        to complete the process.
                      </p>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  margin: "5px 0",
                  backgroundColor: "#323535dc",
                  color: "white",
                  borderRadius: "8px",
                }}
                expanded={expanded === "panel17"}
                onChange={handleChange("panel17")}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "white",
                      }}
                    />
                  }
                >
                  <Typography style={{ fontSize: "large" }}>
                    Your profile
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className = "accordionDetailsContainer"
                  >
                    <div
                      style={{
                        margin: "10px 0px",
                        color: "rgb(210 210 211)",
                      }}
                    >
                    <p
                        style={{
                          margin: "20px 30px",
                        }}
                      >
                            The profile section contains all the information
                            connected to your Muse ID. You can find the profile
                            button in the Top right corner of all the tabs in the
                            app.
                      </p>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          style={{
                            margin: "30px auto",
                            maxWidth: "90%",
                            backgroundColor:"black",
                            padding:"8px",
                            borderRadius:"5px",
                            
                          }}
                          src={myProfile}
                          alt="myProfile"
                        />
                      </div>
                    </div>
                    </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </div>
{/* ----------------------------------Footer----------------------------- */}
      <MDBFooter className='text-center text-white' style={{ backgroundColor: '#000' }}>


      <div className='text-center  p-4' style={{ margin:"20px",backgroundColor: 'rgba(0, 0, 0, 0.2)', color:"gray" }}>
        © 2022 Copyright 
        <a style={{marginLeft:"10px", marginRight:"10px", color:"gray"}}  href='https://www.musewearables.com/'>
          musewearables.com
        </a>
      </div>
    </MDBFooter>
    {/* ----------------------------------Footer----------------------------- */}

    </>
  );
};

export default FirstPage;
